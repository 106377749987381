import React, {useEffect, useState} from "react";
import {Collapse, Collapsible, Descriptions, Typography} from "@douyinfe/semi-ui";
import {FullContractState} from "everscale-inpage-provider/dist/models";
import {Contract} from "everscale-inpage-provider";
import {AuctionAbi, AuctionRootAbi, DeAuctionTip3Abi} from "../../common/abi";
import {ever} from "../Wallet";
import {convertTons} from "../../common";
import moment from "moment";
import {BASE_SCAN_URL} from "../../constants";

export type DeAuctionProps = {
  root: Contract<typeof AuctionRootAbi>
  nonce: number
}
export const DeAuction: React.FC<DeAuctionProps> = ({
                                                      root,
                                                      nonce
                                                    }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isDisplayFull, setDisplayFull] = useState<boolean>(false);
  const [contract, setContract] = useState<Contract<typeof DeAuctionTip3Abi>>();
  const [contractState, setContractState] = useState<FullContractState>();
  const [displayData, setDisplayData] = useState<any[]>();
  const {Text} = Typography;


  useEffect(() => {
    (async () => {
      const address = (await root.methods.expectedDeAuction({answerId: 0, nonce}).call()).deAuction
      const _contract = new ever.Contract(DeAuctionTip3Abi, address);
      const cachedState = (await ever.getFullContractState({address: address})).state
      setContract(_contract);
      setContractState(cachedState);
      const description = (await _contract.methods._description({}).call({cachedState}))._description;
      let data = [
        {key: 'Description', value: description},
        // {key: 'Quoting Price', value: convertTons(_details.quotingPrice) + ' EVER'},
        // {key: 'Minimal lot size', value: convertTons(_details.minLotSize) + ' EVER'},
        // {key: 'Finish time', value: moment.unix(Number(_details.finishTime)).format(' hh:mm:ss, MMMM Do YYYY')},
        // {key: 'Confirm time', value: moment.unix(Number(_details.confirmTime)).format(' hh:mm:ss, MMMM Do YYYY')},
        // {key: 'DeBid time', value: moment.unix(Number(_details.deBidTime)).format(' hh:mm:ss, MMMM Do YYYY')},
        // {key: 'Fee', value: convertTons(_details.fee) + ' EVER'},
        // {key: 'Deposit', value: convertTons(_details.deposit) + ' EVER'},
        {
          key: 'Address',
          value: <Text link={{href: BASE_SCAN_URL + 'accounts/' + address.toString()}}>
            {address.toString()}
          </Text>
        },
      ];
      setDisplayData(data);

    })()
  }, [])
  return (
    <>
      <Descriptions data={displayData}/>

    </>
  )
}