import React, {useEffect, useState} from 'react';
import {Banner, Button, Col, Layout, Nav, Row, Spin} from '@douyinfe/semi-ui';
import {IconHelpCircle} from '@douyinfe/semi-icons';
// import {Mutex} from "@broxus/await-semaphore";
// import {ProviderRpcClient, Subscriber} from "everscale-inpage-provider";
//
import {Address, Contract} from 'everscale-inpage-provider';

import './styles/main.scss';
//
// // import {Navbar} from './components/Navbar';
import {createRoot} from "react-dom/client";
import {ever, Wallet} from "./components/Wallet";
import {AuctionAbi, AuctionRootAbi} from "./common/abi";
import {AUCTION_CODE_HASH, AUCTION_ROOT_ADDRESS} from "./constants";
import {Auction} from "./components/Auction";

export const getAuctionByNonce = async (root: Contract<typeof AuctionRootAbi>, nonce: number) => {
  const auctionAddress = (await root.methods.expectedAuction({answerId: 0, nonce}).call()).auction
  // const auctionDetails = (await auction.methods.getDetails({answerId: 0}).call()).details
  // console.log(auctionDetails);
  return new ever.Contract(AuctionAbi, auctionAddress)
}

const App: React.FC = () => {
  const {Header, Footer, Content} = Layout;
  const [, setAuctionNonce] = useState<number>(0);
  const [isAuctionActive, setIsAuctionActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [auctions, setAuctions] = useState<JSX.Element[]>([]);
  const auctionRoot = new ever.Contract(AuctionRootAbi, AUCTION_ROOT_ADDRESS);

  const preloadApp = (async () => {
    const nonce = Number((await auctionRoot.methods._nonce({}).call())['_nonce'])
    setAuctionNonce(nonce);
    const _isAuctionActive = (await auctionRoot.methods._isActionNow({}).call())._isActionNow;
    setIsAuctionActive(_isAuctionActive);
    const _activeAuction = (await auctionRoot.methods._auction({}).call())._auction
    const accounts = (await ever.getAccountsByCodeHash({codeHash: AUCTION_CODE_HASH, limit: 50})).accounts;
    let _auctions = [];
    let _active;
    for (let account of accounts) {
      const _contract = new ever.Contract(AuctionAbi, account);
      const _contractState = (await ever.getFullContractState({address: account})).state;
      const _nonce = Number((await _contract.methods._nonce({}).call({cachedState: _contractState}))._nonce);
      const _root = (await _contract.methods._root({}).call({cachedState: _contractState}))._root;
      if (_root.toString() === auctionRoot.address.toString()) {
        _auctions.push({contract: _contract, state: _contractState, nonce: _nonce});
      }
      // getAuctionByNonce(auctionRoot, i).then((auction) => setAuctions(value => value.set(i, auction)))
    }
    const auctionsToDisplay = []
    _auctions.sort((a, b) => a.nonce < b.nonce ? 1 : -1)
    for (let i = 0; i < _auctions.length; i++) {
      let auction = _auctions[i]
      console.log(auction.nonce)
      auctionsToDisplay.push(
        <Row type="flex" justify="center" style={{paddingTop: '12px'}}>
          <Col span={14}>
            <Auction
              root={auctionRoot}
              address={auction!.contract.address}
              displayFull={_isAuctionActive && auction!.contract.address.toString() === _activeAuction.toString()}
              deAucFrom={auction.nonce}
              deAucTo={i === 0 ? nonce : _auctions[i - 1].nonce}
            />
          </Col>
        </Row>
      )

    }
    setAuctions(auctionsToDisplay)
  })

  // useEffect(() => {
  //   console.log(auctions)
  // }, [auctions]);

  const onWalletInitialized = () => {
    setIsLoading(true);
    preloadApp().then(() => setIsLoading(false));
  }
  return (
    <Layout>
      <Header style={{backgroundColor: 'var(--semi-color-bg-1)'}}>
        <div>
          <Nav mode='horizontal' defaultSelectedKeys={['Home']}>
            <Nav.Item itemKey='Home' text='NEVER auction'/>
            <Nav.Footer>
              {/*<Button*/}
              {/*  theme="borderless"*/}
              {/*  icon={<IconHelpCircle size="large"/>}*/}
              {/*  style={{*/}
              {/*    color: 'var(--semi-color-text-2)',*/}
              {/*    marginRight: '12px',*/}
              {/*  }}*/}
              {/*/>*/}
              <Wallet onInitialized={onWalletInitialized}/>
            </Nav.Footer>
          </Nav>
        </div>
      </Header>
      <Content
        style={{
          // paddingTop: '24px',
          // backgroundColor: 'var(--semi-color-bg-0)'
        }}
      >
        <div className="grid">
          {isLoading ? (
            <Row type="flex" justify="center" style={{paddingTop: '24px'}}>
              <Col span={1}>
                <Spin size="large"/>
              </Col>
            </Row>
          ) : (
            isAuctionActive ? (
              <></>
              // <Row type="flex" justify="center">
              //   <Col span={12}>
              //     <Auction root={auctionRoot} nonce={auctionNonce - 1} displayFull={true}/>
              //   </Col>
              // </Row>
            ) : (
              <>
                <Banner
                  type="warning"
                  description="There is currently no active auction."
                />
              </>
            )
          )}
          {auctions}
        </div>
      </Content>
      {/*<Footer*/}
      {/*  style={{*/}
      {/*    display: 'flex',*/}
      {/*    justifyContent: 'space-between',*/}
      {/*    padding: '20px',*/}
      {/*    color: 'var(--semi-color-text-2)',*/}
      {/*    backgroundColor: 'rgba(var(--semi-grey-0), 1)',*/}
      {/*  }}*/}
      {/*>*/}
      {/*          <span*/}
      {/*            style={{*/}
      {/*              display: 'flex',*/}
      {/*              alignItems: 'center',*/}
      {/*            }}*/}
      {/*          >*/}
      {/*              <IconBytedanceLogo size='large' style={{marginRight: '8px'}}/>*/}
      {/*              <span>Copyright © 2019 ByteDance. All Rights Reserved. </span>*/}
      {/*          </span>*/}
      {/*  <span>*/}
      {/*              <span style={{marginRight: '24px'}}>Customer Service</span>*/}
      {/*              <span>Feedback</span>*/}
      {/*          </span>*/}
      {/*</Footer>*/}
    </Layout>
  );
};

(async () => {
  const container = document.getElementById('root');
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript
  root.render(<App/>);
})();