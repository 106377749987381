export const AuctionAbi = {
  "ABI version": 2,
  "version": "2.2",
  "header": ["pubkey", "time", "expire"],
  "functions": [
    {
      "name": "getDetails",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {
          "components": [{"name": "root", "type": "address"}, {"name": "fee", "type": "uint128"}, {
            "name": "deposit",
            "type": "uint128"
          }, {"name": "deBidTime", "type": "uint32"}, {"name": "confirmTime", "type": "uint32"}, {
            "name": "finishTime",
            "type": "uint32"
          }, {"name": "minLotSize", "type": "uint128"}, {"name": "quotingPrice", "type": "uint128"}],
          "name": "details",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "getPhase",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "phase", "type": "uint8"}
      ]
    },
    {
      "name": "getWinner",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {
          "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
            "name": "amount",
            "type": "uint128"
          }, {"name": "value", "type": "uint128"}], "name": "winner", "type": "tuple"
        }
      ]
    },
    {
      "name": "makeBid",
      "inputs": [
        {"name": "hash", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "makeDeBid",
      "inputs": [
        {"name": "nonce", "type": "uint64"},
        {"name": "hash", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "removeBid",
      "inputs": [
        {"name": "hash", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "onRemoveBid",
      "inputs": [
        {"name": "hash", "type": "uint256"},
        {"name": "owner", "type": "address"}
      ],
      "outputs": []
    },
    {
      "name": "confirmBid",
      "inputs": [
        {"name": "price", "type": "uint128"},
        {"name": "amount", "type": "uint128"},
        {"name": "salt", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "onConfirmBid",
      "inputs": [
        {"name": "hash", "type": "uint256"},
        {
          "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
            "name": "amount",
            "type": "uint128"
          }, {"name": "value", "type": "uint128"}], "name": "data", "type": "tuple"
        },
        {"name": "msgValue", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "calcBidHash",
      "inputs": [
        {"name": "price", "type": "uint128"},
        {"name": "amount", "type": "uint128"},
        {"name": "sender", "type": "address"},
        {"name": "salt", "type": "uint256"}
      ],
      "outputs": [
        {"name": "hash", "type": "uint256"}
      ]
    },
    {
      "name": "finish",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "calcAddress",
      "inputs": [
        {"name": "stateInit", "type": "cell"}
      ],
      "outputs": [
        {"name": "value0", "type": "address"}
      ]
    },
    {
      "name": "constructor",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "_platformCode",
      "inputs": [],
      "outputs": [
        {"name": "_platformCode", "type": "cell"}
      ]
    },
    {
      "name": "_root",
      "inputs": [],
      "outputs": [
        {"name": "_root", "type": "address"}
      ]
    },
    {
      "name": "_nonce",
      "inputs": [],
      "outputs": [
        {"name": "_nonce", "type": "uint64"}
      ]
    },
    {
      "name": "_minLotSize",
      "inputs": [],
      "outputs": [
        {"name": "_minLotSize", "type": "uint128"}
      ]
    },
    {
      "name": "_quotingPrice",
      "inputs": [],
      "outputs": [
        {"name": "_quotingPrice", "type": "uint128"}
      ]
    },
    {
      "name": "_fee",
      "inputs": [],
      "outputs": [
        {"name": "_fee", "type": "uint128"}
      ]
    },
    {
      "name": "_deposit",
      "inputs": [],
      "outputs": [
        {"name": "_deposit", "type": "uint128"}
      ]
    },
    {
      "name": "_bidCode",
      "inputs": [],
      "outputs": [
        {"name": "_bidCode", "type": "cell"}
      ]
    },
    {
      "name": "_deBidTime",
      "inputs": [],
      "outputs": [
        {"name": "_deBidTime", "type": "uint32"}
      ]
    },
    {
      "name": "_confirmTime",
      "inputs": [],
      "outputs": [
        {"name": "_confirmTime", "type": "uint32"}
      ]
    },
    {
      "name": "_finishTime",
      "inputs": [],
      "outputs": [
        {"name": "_finishTime", "type": "uint32"}
      ]
    },
    {
      "name": "_bidsCount",
      "inputs": [],
      "outputs": [
        {"name": "_bidsCount", "type": "uint64"}
      ]
    },
    {
      "name": "_confirmBidsCount",
      "inputs": [],
      "outputs": [
        {"name": "_confirmBidsCount", "type": "uint64"}
      ]
    },
    {
      "name": "_finished",
      "inputs": [],
      "outputs": [
        {"name": "_finished", "type": "bool"}
      ]
    },
    {
      "name": "_winner",
      "inputs": [],
      "outputs": [
        {
          "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
            "name": "amount",
            "type": "uint128"
          }, {"name": "value", "type": "uint128"}], "name": "_winner", "type": "tuple"
        }
      ]
    }
  ],
  "data": [],
  "events": [
    {
      "name": "MakeBid",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "hash", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "RemoveBid",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "hash", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "ConfirmBid",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "hash", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "Finish",
      "inputs": [
        {"name": "success", "type": "bool"},
        {
          "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
            "name": "amount",
            "type": "uint128"
          }, {"name": "value", "type": "uint128"}], "name": "winner", "type": "tuple"
        }
      ],
      "outputs": []
    }
  ],
  "fields": [
    {"name": "_pubkey", "type": "uint256"},
    {"name": "_timestamp", "type": "uint64"},
    {"name": "_constructorFlag", "type": "bool"},
    {"name": "_platformCode", "type": "cell"},
    {"name": "_root", "type": "address"},
    {"name": "_nonce", "type": "uint64"},
    {"name": "_minLotSize", "type": "uint128"},
    {"name": "_quotingPrice", "type": "uint128"},
    {"name": "_fee", "type": "uint128"},
    {"name": "_deposit", "type": "uint128"},
    {"name": "_bidCode", "type": "cell"},
    {"name": "_deBidTime", "type": "uint32"},
    {"name": "_confirmTime", "type": "uint32"},
    {"name": "_finishTime", "type": "uint32"},
    {"name": "_bidsCount", "type": "uint64"},
    {"name": "_confirmBidsCount", "type": "uint64"},
    {"name": "_finished", "type": "bool"},
    {
      "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
        "name": "amount",
        "type": "uint128"
      }, {"name": "value", "type": "uint128"}], "name": "_winner", "type": "tuple"
    },
    {
      "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
        "name": "amount",
        "type": "uint128"
      }, {"name": "value", "type": "uint128"}], "name": "_first", "type": "tuple"
    },
    {
      "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
        "name": "amount",
        "type": "uint128"
      }, {"name": "value", "type": "uint128"}], "name": "_second", "type": "tuple"
    }
  ]
} as const;

export const AuctionRootAbi = {
  "ABI version": 2,
  "version": "2.2",
  "header": ["pubkey", "time", "expire"],
  "functions": [
    {
      "name": "constructor",
      "inputs": [
        {"name": "elector", "type": "address"},
        {
          "components": [{"name": "fee", "type": "uint128"}, {
            "name": "deposit",
            "type": "uint128"
          }, {"name": "openDuration", "type": "uint32"}, {
            "name": "deBidDuration",
            "type": "uint32"
          }, {"name": "confirmDuration", "type": "uint32"}, {"name": "bidCode", "type": "cell"}],
          "name": "auctionConfig",
          "type": "tuple"
        },
        {
          "components": [{"name": "subOpenDuration", "type": "uint32"}, {
            "name": "subConfirmDuration",
            "type": "uint32"
          }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
          "name": "deAuctionGlobalConfig",
          "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "expectedAuction",
      "inputs": [
        {"name": "answerId", "type": "uint32"},
        {"name": "nonce", "type": "uint64"}
      ],
      "outputs": [
        {"name": "auction", "type": "address"}
      ]
    },
    {
      "name": "expectedDeParticipant",
      "inputs": [
        {"name": "answerId", "type": "uint32"},
        {"name": "owner", "type": "address"}
      ],
      "outputs": [
        {"name": "deParticipant", "type": "address"}
      ]
    },
    {
      "name": "expectedDeAuction",
      "inputs": [
        {"name": "answerId", "type": "uint32"},
        {"name": "nonce", "type": "uint64"}
      ],
      "outputs": [
        {"name": "deAuction", "type": "address"}
      ]
    },
    {
      "name": "expectedAuctionHashCode",
      "inputs": [
        {"name": "answerId", "type": "uint32"},
        {"name": "auction", "type": "address"}
      ],
      "outputs": [
        {"name": "hash", "type": "uint256"}
      ]
    },
    {
      "name": "expectedBidHashCode",
      "inputs": [
        {"name": "answerId", "type": "uint32"},
        {"name": "auction", "type": "address"},
        {"name": "owner", "type": "address"}
      ],
      "outputs": [
        {"name": "hash", "type": "uint256"}
      ]
    },
    {
      "name": "getDetails",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "elector", "type": "address"},
        {
          "components": [{"name": "fee", "type": "uint128"}, {
            "name": "deposit",
            "type": "uint128"
          }, {"name": "openDuration", "type": "uint32"}, {
            "name": "deBidDuration",
            "type": "uint32"
          }, {"name": "confirmDuration", "type": "uint32"}, {"name": "bidCode", "type": "cell"}],
          "name": "auctionConfig",
          "type": "tuple"
        },
        {
          "components": [{"name": "subOpenDuration", "type": "uint32"}, {
            "name": "subConfirmDuration",
            "type": "uint32"
          }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
          "name": "deAuctionGlobalConfig",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "getCodes",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "auctionCode", "type": "cell"},
        {"name": "deAuctionCode", "type": "cell"},
        {"name": "deParticipantCode", "type": "cell"}
      ]
    },
    {
      "name": "currentAuction",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "auction", "type": "optional(address)"}
      ]
    },
    {
      "name": "setCodes",
      "inputs": [
        {"name": "platformCode", "type": "cell"},
        {"name": "auctionCode", "type": "cell"},
        {"name": "deAuctionCode", "type": "cell"},
        {"name": "deParticipantCode", "type": "cell"}
      ],
      "outputs": []
    },
    {
      "name": "changeElector",
      "inputs": [
        {"name": "elector", "type": "address"}
      ],
      "outputs": []
    },
    {
      "name": "changeAuctionConfig",
      "inputs": [
        {
          "components": [{"name": "fee", "type": "uint128"}, {
            "name": "deposit",
            "type": "uint128"
          }, {"name": "openDuration", "type": "uint32"}, {
            "name": "deBidDuration",
            "type": "uint32"
          }, {"name": "confirmDuration", "type": "uint32"}, {"name": "bidCode", "type": "cell"}],
          "name": "auctionConfig",
          "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "changeDeAuctionGlobalConfig",
      "inputs": [
        {
          "components": [{"name": "subOpenDuration", "type": "uint32"}, {
            "name": "subConfirmDuration",
            "type": "uint32"
          }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
          "name": "deAuctionGlobalConfig",
          "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "createAuction",
      "inputs": [
        {"name": "minLotSize", "type": "uint128"},
        {"name": "quotingPrice", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "createDeParticipant",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "createDeAuction",
      "inputs": [
        {"name": "owner", "type": "address"},
        {
          "components": [{"name": "description", "type": "string"}, {
            "components": [{
              "name": "min",
              "type": "uint128"
            }, {"name": "max", "type": "uint128"}], "name": "prices", "type": "tuple"
          }, {"name": "deviation", "type": "uint128"}, {
            "name": "aggregatorFee",
            "type": "uint128"
          }, {"name": "aggregator", "type": "address"}, {"name": "aggregatorStake", "type": "uint128"}],
          "name": "initConfig",
          "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "onFinish",
      "inputs": [
        {"name": "success", "type": "bool"},
        {
          "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
            "name": "amount",
            "type": "uint128"
          }, {"name": "value", "type": "uint128"}], "name": "winner", "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "upgrade",
      "inputs": [
        {"name": "code", "type": "cell"}
      ],
      "outputs": []
    },
    {
      "name": "calcAddress",
      "inputs": [
        {"name": "stateInit", "type": "cell"}
      ],
      "outputs": [
        {"name": "value0", "type": "address"}
      ]
    },
    {
      "name": "_platformCode",
      "inputs": [],
      "outputs": [
        {"name": "_platformCode", "type": "cell"}
      ]
    },
    {
      "name": "_randomNonce",
      "inputs": [],
      "outputs": [
        {"name": "_randomNonce", "type": "uint256"}
      ]
    },
    {
      "name": "_elector",
      "inputs": [],
      "outputs": [
        {"name": "_elector", "type": "address"}
      ]
    },
    {
      "name": "_auctionConfig",
      "inputs": [],
      "outputs": [
        {
          "components": [{"name": "fee", "type": "uint128"}, {
            "name": "deposit",
            "type": "uint128"
          }, {"name": "openDuration", "type": "uint32"}, {
            "name": "deBidDuration",
            "type": "uint32"
          }, {"name": "confirmDuration", "type": "uint32"}, {"name": "bidCode", "type": "cell"}],
          "name": "_auctionConfig",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "_deAuctionGlobalConfig",
      "inputs": [],
      "outputs": [
        {
          "components": [{"name": "subOpenDuration", "type": "uint32"}, {
            "name": "subConfirmDuration",
            "type": "uint32"
          }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
          "name": "_deAuctionGlobalConfig",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "_nonce",
      "inputs": [],
      "outputs": [
        {"name": "_nonce", "type": "uint64"}
      ]
    },
    {
      "name": "_isActionNow",
      "inputs": [],
      "outputs": [
        {"name": "_isActionNow", "type": "bool"}
      ]
    },
    {
      "name": "_auction",
      "inputs": [],
      "outputs": [
        {"name": "_auction", "type": "address"}
      ]
    },
    {
      "name": "_auctionCode",
      "inputs": [],
      "outputs": [
        {"name": "_auctionCode", "type": "cell"}
      ]
    },
    {
      "name": "_deAuctionCode",
      "inputs": [],
      "outputs": [
        {"name": "_deAuctionCode", "type": "cell"}
      ]
    },
    {
      "name": "_deParticipantCode",
      "inputs": [],
      "outputs": [
        {"name": "_deParticipantCode", "type": "cell"}
      ]
    }
  ],
  "data": [
    {"key": 1, "name": "_randomNonce", "type": "uint256"}
  ],
  "events": [
    {
      "name": "ElectorChange",
      "inputs": [
        {"name": "from", "type": "address"},
        {"name": "to", "type": "address"}
      ],
      "outputs": []
    },
    {
      "name": "AuctionConfigChange",
      "inputs": [
        {
          "components": [{"name": "fee", "type": "uint128"}, {
            "name": "deposit",
            "type": "uint128"
          }, {"name": "openDuration", "type": "uint32"}, {
            "name": "deBidDuration",
            "type": "uint32"
          }, {"name": "confirmDuration", "type": "uint32"}, {"name": "bidCode", "type": "cell"}],
          "name": "from",
          "type": "tuple"
        },
        {
          "components": [{"name": "fee", "type": "uint128"}, {
            "name": "deposit",
            "type": "uint128"
          }, {"name": "openDuration", "type": "uint32"}, {
            "name": "deBidDuration",
            "type": "uint32"
          }, {"name": "confirmDuration", "type": "uint32"}, {"name": "bidCode", "type": "cell"}],
          "name": "to",
          "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "DeAuctionGlobalConfigChange",
      "inputs": [
        {
          "components": [{"name": "subOpenDuration", "type": "uint32"}, {
            "name": "subConfirmDuration",
            "type": "uint32"
          }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
          "name": "from",
          "type": "tuple"
        },
        {
          "components": [{"name": "subOpenDuration", "type": "uint32"}, {
            "name": "subConfirmDuration",
            "type": "uint32"
          }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
          "name": "to",
          "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "NewAuction",
      "inputs": [
        {"name": "auction", "type": "address"}
      ],
      "outputs": []
    },
    {
      "name": "NewDeParticipant",
      "inputs": [
        {"name": "deParticipant", "type": "address"}
      ],
      "outputs": []
    },
    {
      "name": "NewDeAuction",
      "inputs": [
        {"name": "auction", "type": "address"},
        {"name": "deAuction", "type": "address"}
      ],
      "outputs": []
    },
    {
      "name": "Finish",
      "inputs": [
        {"name": "success", "type": "bool"},
        {
          "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
            "name": "amount",
            "type": "uint128"
          }, {"name": "value", "type": "uint128"}], "name": "winner", "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "CodeUpgraded",
      "inputs": [],
      "outputs": []
    }
  ],
  "fields": [
    {"name": "_pubkey", "type": "uint256"},
    {"name": "_timestamp", "type": "uint64"},
    {"name": "_constructorFlag", "type": "bool"},
    {"name": "_platformCode", "type": "cell"},
    {"name": "_randomNonce", "type": "uint256"},
    {"name": "_elector", "type": "address"},
    {
      "components": [{"name": "fee", "type": "uint128"}, {"name": "deposit", "type": "uint128"}, {
        "name": "openDuration",
        "type": "uint32"
      }, {"name": "deBidDuration", "type": "uint32"}, {"name": "confirmDuration", "type": "uint32"}, {
        "name": "bidCode",
        "type": "cell"
      }], "name": "_auctionConfig", "type": "tuple"
    },
    {
      "components": [{"name": "subOpenDuration", "type": "uint32"}, {
        "name": "subConfirmDuration",
        "type": "uint32"
      }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
      "name": "_deAuctionGlobalConfig",
      "type": "tuple"
    },
    {"name": "_nonce", "type": "uint64"},
    {"name": "_isActionNow", "type": "bool"},
    {"name": "_auction", "type": "address"},
    {"name": "_auctionCode", "type": "cell"},
    {"name": "_deAuctionCode", "type": "cell"},
    {"name": "_deParticipantCode", "type": "cell"}
  ]
} as const;

export const BidAbi = {
  "ABI version": 2,
  "version": "2.2",
  "header": ["pubkey", "time", "expire"],
  "functions": [
    {
      "name": "constructor",
      "inputs": [
        {"name": "owner", "type": "address"}
      ],
      "outputs": []
    },
    {
      "name": "getDetails",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "value0", "type": "address"},
        {"name": "value1", "type": "uint256"},
        {"name": "value2", "type": "address"}
      ]
    },
    {
      "name": "remove",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "value0", "type": "uint256"},
        {"name": "value1", "type": "address"}
      ]
    },
    {
      "name": "confirm",
      "inputs": [
        {"name": "answerId", "type": "uint32"},
        {
          "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
            "name": "amount",
            "type": "uint128"
          }, {"name": "value", "type": "uint128"}], "name": "data", "type": "tuple"
        },
        {"name": "msgValue", "type": "uint128"}
      ],
      "outputs": [
        {"name": "value0", "type": "uint256"},
        {
          "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
            "name": "amount",
            "type": "uint128"
          }, {"name": "value", "type": "uint128"}], "name": "value1", "type": "tuple"
        },
        {"name": "value2", "type": "uint128"}
      ]
    }
  ],
  "data": [
    {"key": 1, "name": "_auction", "type": "address"},
    {"key": 2, "name": "_hash", "type": "uint256"}
  ],
  "events": [],
  "fields": [
    {"name": "_pubkey", "type": "uint256"},
    {"name": "_timestamp", "type": "uint64"},
    {"name": "_constructorFlag", "type": "bool"},
    {"name": "_auction", "type": "address"},
    {"name": "_hash", "type": "uint256"},
    {"name": "_owner", "type": "address"}
  ]
} as const;

export const DeAuctionEccAbi = {
  "ABI version": 2,
  "version": "2.2",
  "header": ["pubkey", "time", "expire"],
  "functions": [
    {
      "name": "getNeverDetails",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "neverID", "type": "uint32"},
        {"name": "electorVault", "type": "address"}
      ]
    },
    {
      "name": "onAcceptTokensTransfer",
      "inputs": [
        {"name": "amount", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "buildInitDetails",
      "inputs": [
        {"name": "neverID", "type": "uint32"},
        {"name": "electorVault", "type": "address"}
      ],
      "outputs": [
        {"name": "details", "type": "cell"}
      ]
    },
    {
      "name": "onGetDetails",
      "inputs": [
        {
          "components": [{"name": "root", "type": "address"}, {"name": "fee", "type": "uint128"}, {
            "name": "deposit",
            "type": "uint128"
          }, {"name": "deBidTime", "type": "uint32"}, {"name": "confirmTime", "type": "uint32"}, {
            "name": "finishTime",
            "type": "uint32"
          }, {"name": "minLotSize", "type": "uint128"}, {"name": "quotingPrice", "type": "uint128"}],
          "name": "details",
          "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "getDetails",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "root", "type": "address"},
        {"name": "auction", "type": "address"},
        {
          "components": [{
            "components": [{"name": "description", "type": "string"}, {
              "components": [{
                "name": "min",
                "type": "uint128"
              }, {"name": "max", "type": "uint128"}], "name": "prices", "type": "tuple"
            }, {"name": "deviation", "type": "uint128"}, {
              "name": "aggregatorFee",
              "type": "uint128"
            }, {"name": "aggregator", "type": "address"}, {"name": "aggregatorStake", "type": "uint128"}],
            "name": "init",
            "type": "tuple"
          }, {
            "components": [{"name": "subOpenDuration", "type": "uint32"}, {
              "name": "subConfirmDuration",
              "type": "uint32"
            }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
            "name": "global",
            "type": "tuple"
          }], "name": "details", "type": "tuple"
        }
      ]
    },
    {
      "name": "getStakes",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "total", "type": "uint128"},
        {"name": "aggregator", "type": "uint128"}
      ]
    },
    {
      "name": "getTimes",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "subConfirmTime", "type": "uint32"},
        {"name": "makeBidTime", "type": "uint32"}
      ]
    },
    {
      "name": "getPhase",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "phase", "type": "uint8"}
      ]
    },
    {
      "name": "getDistribution",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "everValue", "type": "uint128"},
        {"name": "neverValue", "type": "uint128"},
        {"name": "aggregatorReward", "type": "uint128"}
      ]
    },
    {
      "name": "stake",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "value", "type": "uint128"},
        {"name": "priceHash", "type": "optional(uint256)"}
      ],
      "outputs": []
    },
    {
      "name": "removeStake",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "confirmPrice",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "price", "type": "uint128"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "finishSubVoting",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "allowedPrice",
      "inputs": [],
      "outputs": [
        {
          "components": [{"name": "min", "type": "uint128"}, {"name": "max", "type": "uint128"}],
          "name": "allowed",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "calcBidHash",
      "inputs": [
        {"name": "price", "type": "uint128"},
        {"name": "salt", "type": "uint256"}
      ],
      "outputs": [
        {"name": "hash", "type": "uint256"}
      ]
    },
    {
      "name": "makeBid",
      "inputs": [
        {"name": "hash", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "onMakeBid",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "onRemoveBid",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "confirmBid",
      "inputs": [
        {"name": "price", "type": "uint128"},
        {"name": "salt", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "onConfirmBid",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "onWin",
      "inputs": [
        {"name": "price", "type": "uint128"},
        {"name": "amount", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "pingAuctionFinish",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "onPingAuctionFinish",
      "inputs": [
        {"name": "phase", "type": "uint8"}
      ],
      "outputs": []
    },
    {
      "name": "onGetWinner",
      "inputs": [
        {
          "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
            "name": "amount",
            "type": "uint128"
          }, {"name": "value", "type": "uint128"}], "name": "winner", "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "checkAggregator",
      "inputs": [],
      "outputs": [
        {"name": "isFair", "type": "bool"}
      ]
    },
    {
      "name": "slash",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "claim",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "calcAddress",
      "inputs": [
        {"name": "stateInit", "type": "cell"}
      ],
      "outputs": [
        {"name": "value0", "type": "address"}
      ]
    },
    {
      "name": "constructor",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "_platformCode",
      "inputs": [],
      "outputs": [
        {"name": "_platformCode", "type": "cell"}
      ]
    },
    {
      "name": "_root",
      "inputs": [],
      "outputs": [
        {"name": "_root", "type": "address"}
      ]
    },
    {
      "name": "_nonce",
      "inputs": [],
      "outputs": [
        {"name": "_nonce", "type": "uint64"}
      ]
    },
    {
      "name": "_auction",
      "inputs": [],
      "outputs": [
        {"name": "_auction", "type": "address"}
      ]
    },
    {
      "name": "_description",
      "inputs": [],
      "outputs": [
        {"name": "_description", "type": "string"}
      ]
    },
    {
      "name": "_prices",
      "inputs": [],
      "outputs": [
        {
          "components": [{"name": "min", "type": "uint128"}, {"name": "max", "type": "uint128"}],
          "name": "_prices",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "_deviation",
      "inputs": [],
      "outputs": [
        {"name": "_deviation", "type": "uint128"}
      ]
    },
    {
      "name": "_aggregatorFee",
      "inputs": [],
      "outputs": [
        {"name": "_aggregatorFee", "type": "uint128"}
      ]
    },
    {
      "name": "_aggregator",
      "inputs": [],
      "outputs": [
        {"name": "_aggregator", "type": "address"}
      ]
    },
    {
      "name": "_aggregatorStake",
      "inputs": [],
      "outputs": [
        {"name": "_aggregatorStake", "type": "uint128"}
      ]
    },
    {
      "name": "_globalConfig",
      "inputs": [],
      "outputs": [
        {
          "components": [{"name": "subOpenDuration", "type": "uint32"}, {
            "name": "subConfirmDuration",
            "type": "uint32"
          }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
          "name": "_globalConfig",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "_subConfirmTime",
      "inputs": [],
      "outputs": [
        {"name": "_subConfirmTime", "type": "uint32"}
      ]
    },
    {
      "name": "_makeBidTime",
      "inputs": [],
      "outputs": [
        {"name": "_makeBidTime", "type": "uint32"}
      ]
    },
    {
      "name": "_phase",
      "inputs": [],
      "outputs": [
        {"name": "_phase", "type": "uint8"}
      ]
    },
    {
      "name": "_auctionDetails",
      "inputs": [],
      "outputs": [
        {
          "components": [{"name": "root", "type": "address"}, {"name": "fee", "type": "uint128"}, {
            "name": "deposit",
            "type": "uint128"
          }, {"name": "deBidTime", "type": "uint32"}, {"name": "confirmTime", "type": "uint32"}, {
            "name": "finishTime",
            "type": "uint32"
          }, {"name": "minLotSize", "type": "uint128"}, {"name": "quotingPrice", "type": "uint128"}],
          "name": "_auctionDetails",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "_totalStake",
      "inputs": [],
      "outputs": [
        {"name": "_totalStake", "type": "uint128"}
      ]
    },
    {
      "name": "_everValue",
      "inputs": [],
      "outputs": [
        {"name": "_everValue", "type": "uint128"}
      ]
    },
    {
      "name": "_neverValue",
      "inputs": [],
      "outputs": [
        {"name": "_neverValue", "type": "uint128"}
      ]
    },
    {
      "name": "_aggregatorReward",
      "inputs": [],
      "outputs": [
        {"name": "_aggregatorReward", "type": "uint128"}
      ]
    },
    {
      "name": "_neverID",
      "inputs": [],
      "outputs": [
        {"name": "_neverID", "type": "uint32"}
      ]
    },
    {
      "name": "_electorVault",
      "inputs": [],
      "outputs": [
        {"name": "_electorVault", "type": "address"}
      ]
    }
  ],
  "data": [],
  "events": [
    {
      "name": "Stake",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "RemoveStake",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "ConfirmPrice",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "price", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "FinishSubVoting",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "MakeBid",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "ConfirmBid",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "Win",
      "inputs": [
        {"name": "price", "type": "uint128"},
        {"name": "amount", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "Lose",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "Distribution",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "Slashed",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "Claim",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "everValue", "type": "uint128"},
        {"name": "neverValue", "type": "uint128"}
      ],
      "outputs": []
    }
  ],
  "fields": [
    {"name": "_pubkey", "type": "uint256"},
    {"name": "_timestamp", "type": "uint64"},
    {"name": "_constructorFlag", "type": "bool"},
    {"name": "_platformCode", "type": "cell"},
    {"name": "_root", "type": "address"},
    {"name": "_nonce", "type": "uint64"},
    {"name": "_auction", "type": "address"},
    {"name": "_description", "type": "string"},
    {
      "components": [{"name": "min", "type": "uint128"}, {"name": "max", "type": "uint128"}],
      "name": "_prices",
      "type": "tuple"
    },
    {"name": "_deviation", "type": "uint128"},
    {"name": "_aggregatorFee", "type": "uint128"},
    {"name": "_aggregator", "type": "address"},
    {"name": "_aggregatorStake", "type": "uint128"},
    {
      "components": [{"name": "subOpenDuration", "type": "uint32"}, {
        "name": "subConfirmDuration",
        "type": "uint32"
      }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
      "name": "_globalConfig",
      "type": "tuple"
    },
    {"name": "_subConfirmTime", "type": "uint32"},
    {"name": "_makeBidTime", "type": "uint32"},
    {"name": "_phase", "type": "uint8"},
    {
      "components": [{"name": "root", "type": "address"}, {"name": "fee", "type": "uint128"}, {
        "name": "deposit",
        "type": "uint128"
      }, {"name": "deBidTime", "type": "uint32"}, {"name": "confirmTime", "type": "uint32"}, {
        "name": "finishTime",
        "type": "uint32"
      }, {"name": "minLotSize", "type": "uint128"}, {"name": "quotingPrice", "type": "uint128"}],
      "name": "_auctionDetails",
      "type": "tuple"
    },
    {"name": "_totalStake", "type": "uint128"},
    {"name": "_avgTotal", "type": "uint256"},
    {"name": "_avgStake", "type": "uint128"},
    {"name": "_avgPrice", "type": "uint128"},
    {"name": "_everValue", "type": "uint128"},
    {"name": "_neverValue", "type": "uint128"},
    {"name": "_aggregatorReward", "type": "uint128"},
    {"name": "_neverID", "type": "uint32"},
    {"name": "_electorVault", "type": "address"}
  ]
} as const;

export const DeAuctionTip3Abi = {
  "ABI version": 2,
  "version": "2.2",
  "header": ["pubkey", "time", "expire"],
  "functions": [
    {
      "name": "onWalletDeployed",
      "inputs": [
        {"name": "wallet", "type": "address"}
      ],
      "outputs": []
    },
    {
      "name": "getNeverDetails",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "neverRoot", "type": "address"},
        {"name": "neverWallet", "type": "address"}
      ]
    },
    {
      "name": "onAcceptTokensMint",
      "inputs": [
        {"name": "value0", "type": "address"},
        {"name": "amount", "type": "uint128"},
        {"name": "value2", "type": "address"},
        {"name": "value3", "type": "cell"}
      ],
      "outputs": []
    },
    {
      "name": "buildInitDetails",
      "inputs": [
        {"name": "neverRoot", "type": "address"}
      ],
      "outputs": [
        {"name": "details", "type": "cell"}
      ]
    },
    {
      "name": "onGetDetails",
      "inputs": [
        {
          "components": [{"name": "root", "type": "address"}, {"name": "fee", "type": "uint128"}, {
            "name": "deposit",
            "type": "uint128"
          }, {"name": "deBidTime", "type": "uint32"}, {"name": "confirmTime", "type": "uint32"}, {
            "name": "finishTime",
            "type": "uint32"
          }, {"name": "minLotSize", "type": "uint128"}, {"name": "quotingPrice", "type": "uint128"}],
          "name": "details",
          "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "getDetails",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "root", "type": "address"},
        {"name": "auction", "type": "address"},
        {
          "components": [{
            "components": [{"name": "description", "type": "string"}, {
              "components": [{
                "name": "min",
                "type": "uint128"
              }, {"name": "max", "type": "uint128"}], "name": "prices", "type": "tuple"
            }, {"name": "deviation", "type": "uint128"}, {
              "name": "aggregatorFee",
              "type": "uint128"
            }, {"name": "aggregator", "type": "address"}, {"name": "aggregatorStake", "type": "uint128"}],
            "name": "init",
            "type": "tuple"
          }, {
            "components": [{"name": "subOpenDuration", "type": "uint32"}, {
              "name": "subConfirmDuration",
              "type": "uint32"
            }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
            "name": "global",
            "type": "tuple"
          }], "name": "details", "type": "tuple"
        }
      ]
    },
    {
      "name": "getStakes",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "total", "type": "uint128"},
        {"name": "aggregator", "type": "uint128"}
      ]
    },
    {
      "name": "getTimes",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "subConfirmTime", "type": "uint32"},
        {"name": "makeBidTime", "type": "uint32"}
      ]
    },
    {
      "name": "getPhase",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "phase", "type": "uint8"}
      ]
    },
    {
      "name": "getDistribution",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "everValue", "type": "uint128"},
        {"name": "neverValue", "type": "uint128"},
        {"name": "aggregatorReward", "type": "uint128"}
      ]
    },
    {
      "name": "stake",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "value", "type": "uint128"},
        {"name": "priceHash", "type": "optional(uint256)"}
      ],
      "outputs": []
    },
    {
      "name": "removeStake",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "confirmPrice",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "price", "type": "uint128"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "finishSubVoting",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "allowedPrice",
      "inputs": [],
      "outputs": [
        {
          "components": [{"name": "min", "type": "uint128"}, {"name": "max", "type": "uint128"}],
          "name": "allowed",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "calcBidHash",
      "inputs": [
        {"name": "price", "type": "uint128"},
        {"name": "salt", "type": "uint256"}
      ],
      "outputs": [
        {"name": "hash", "type": "uint256"}
      ]
    },
    {
      "name": "makeBid",
      "inputs": [
        {"name": "hash", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "onMakeBid",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "onRemoveBid",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "confirmBid",
      "inputs": [
        {"name": "price", "type": "uint128"},
        {"name": "salt", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "onConfirmBid",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "onWin",
      "inputs": [
        {"name": "price", "type": "uint128"},
        {"name": "amount", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "pingAuctionFinish",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "onPingAuctionFinish",
      "inputs": [
        {"name": "phase", "type": "uint8"}
      ],
      "outputs": []
    },
    {
      "name": "onGetWinner",
      "inputs": [
        {
          "components": [{"name": "owner", "type": "address"}, {"name": "price", "type": "uint128"}, {
            "name": "amount",
            "type": "uint128"
          }, {"name": "value", "type": "uint128"}], "name": "winner", "type": "tuple"
        }
      ],
      "outputs": []
    },
    {
      "name": "checkAggregator",
      "inputs": [],
      "outputs": [
        {"name": "isFair", "type": "bool"}
      ]
    },
    {
      "name": "slash",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "claim",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "calcAddress",
      "inputs": [
        {"name": "stateInit", "type": "cell"}
      ],
      "outputs": [
        {"name": "value0", "type": "address"}
      ]
    },
    {
      "name": "constructor",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "_platformCode",
      "inputs": [],
      "outputs": [
        {"name": "_platformCode", "type": "cell"}
      ]
    },
    {
      "name": "_root",
      "inputs": [],
      "outputs": [
        {"name": "_root", "type": "address"}
      ]
    },
    {
      "name": "_nonce",
      "inputs": [],
      "outputs": [
        {"name": "_nonce", "type": "uint64"}
      ]
    },
    {
      "name": "_auction",
      "inputs": [],
      "outputs": [
        {"name": "_auction", "type": "address"}
      ]
    },
    {
      "name": "_description",
      "inputs": [],
      "outputs": [
        {"name": "_description", "type": "string"}
      ]
    },
    {
      "name": "_prices",
      "inputs": [],
      "outputs": [
        {
          "components": [{"name": "min", "type": "uint128"}, {"name": "max", "type": "uint128"}],
          "name": "_prices",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "_deviation",
      "inputs": [],
      "outputs": [
        {"name": "_deviation", "type": "uint128"}
      ]
    },
    {
      "name": "_aggregatorFee",
      "inputs": [],
      "outputs": [
        {"name": "_aggregatorFee", "type": "uint128"}
      ]
    },
    {
      "name": "_aggregator",
      "inputs": [],
      "outputs": [
        {"name": "_aggregator", "type": "address"}
      ]
    },
    {
      "name": "_aggregatorStake",
      "inputs": [],
      "outputs": [
        {"name": "_aggregatorStake", "type": "uint128"}
      ]
    },
    {
      "name": "_globalConfig",
      "inputs": [],
      "outputs": [
        {
          "components": [{"name": "subOpenDuration", "type": "uint32"}, {
            "name": "subConfirmDuration",
            "type": "uint32"
          }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
          "name": "_globalConfig",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "_subConfirmTime",
      "inputs": [],
      "outputs": [
        {"name": "_subConfirmTime", "type": "uint32"}
      ]
    },
    {
      "name": "_makeBidTime",
      "inputs": [],
      "outputs": [
        {"name": "_makeBidTime", "type": "uint32"}
      ]
    },
    {
      "name": "_phase",
      "inputs": [],
      "outputs": [
        {"name": "_phase", "type": "uint8"}
      ]
    },
    {
      "name": "_auctionDetails",
      "inputs": [],
      "outputs": [
        {
          "components": [{"name": "root", "type": "address"}, {"name": "fee", "type": "uint128"}, {
            "name": "deposit",
            "type": "uint128"
          }, {"name": "deBidTime", "type": "uint32"}, {"name": "confirmTime", "type": "uint32"}, {
            "name": "finishTime",
            "type": "uint32"
          }, {"name": "minLotSize", "type": "uint128"}, {"name": "quotingPrice", "type": "uint128"}],
          "name": "_auctionDetails",
          "type": "tuple"
        }
      ]
    },
    {
      "name": "_totalStake",
      "inputs": [],
      "outputs": [
        {"name": "_totalStake", "type": "uint128"}
      ]
    },
    {
      "name": "_everValue",
      "inputs": [],
      "outputs": [
        {"name": "_everValue", "type": "uint128"}
      ]
    },
    {
      "name": "_neverValue",
      "inputs": [],
      "outputs": [
        {"name": "_neverValue", "type": "uint128"}
      ]
    },
    {
      "name": "_aggregatorReward",
      "inputs": [],
      "outputs": [
        {"name": "_aggregatorReward", "type": "uint128"}
      ]
    },
    {
      "name": "_neverRoot",
      "inputs": [],
      "outputs": [
        {"name": "_neverRoot", "type": "address"}
      ]
    },
    {
      "name": "_neverWallet",
      "inputs": [],
      "outputs": [
        {"name": "_neverWallet", "type": "address"}
      ]
    }
  ],
  "data": [],
  "events": [
    {
      "name": "Stake",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "RemoveStake",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "ConfirmPrice",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "price", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "FinishSubVoting",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "MakeBid",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "ConfirmBid",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "Win",
      "inputs": [
        {"name": "price", "type": "uint128"},
        {"name": "amount", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "Lose",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "Distribution",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "Slashed",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "Claim",
      "inputs": [
        {"name": "owner", "type": "address"},
        {"name": "everValue", "type": "uint128"},
        {"name": "neverValue", "type": "uint128"}
      ],
      "outputs": []
    }
  ],
  "fields": [
    {"name": "_pubkey", "type": "uint256"},
    {"name": "_timestamp", "type": "uint64"},
    {"name": "_constructorFlag", "type": "bool"},
    {"name": "_platformCode", "type": "cell"},
    {"name": "_root", "type": "address"},
    {"name": "_nonce", "type": "uint64"},
    {"name": "_auction", "type": "address"},
    {"name": "_description", "type": "string"},
    {
      "components": [{"name": "min", "type": "uint128"}, {"name": "max", "type": "uint128"}],
      "name": "_prices",
      "type": "tuple"
    },
    {"name": "_deviation", "type": "uint128"},
    {"name": "_aggregatorFee", "type": "uint128"},
    {"name": "_aggregator", "type": "address"},
    {"name": "_aggregatorStake", "type": "uint128"},
    {
      "components": [{"name": "subOpenDuration", "type": "uint32"}, {
        "name": "subConfirmDuration",
        "type": "uint32"
      }, {"name": "makeBidDuration", "type": "uint32"}, {"name": "initDetails", "type": "cell"}],
      "name": "_globalConfig",
      "type": "tuple"
    },
    {"name": "_subConfirmTime", "type": "uint32"},
    {"name": "_makeBidTime", "type": "uint32"},
    {"name": "_phase", "type": "uint8"},
    {
      "components": [{"name": "root", "type": "address"}, {"name": "fee", "type": "uint128"}, {
        "name": "deposit",
        "type": "uint128"
      }, {"name": "deBidTime", "type": "uint32"}, {"name": "confirmTime", "type": "uint32"}, {
        "name": "finishTime",
        "type": "uint32"
      }, {"name": "minLotSize", "type": "uint128"}, {"name": "quotingPrice", "type": "uint128"}],
      "name": "_auctionDetails",
      "type": "tuple"
    },
    {"name": "_totalStake", "type": "uint128"},
    {"name": "_avgTotal", "type": "uint256"},
    {"name": "_avgStake", "type": "uint128"},
    {"name": "_avgPrice", "type": "uint128"},
    {"name": "_everValue", "type": "uint128"},
    {"name": "_neverValue", "type": "uint128"},
    {"name": "_aggregatorReward", "type": "uint128"},
    {"name": "_neverRoot", "type": "address"},
    {"name": "_neverWallet", "type": "address"}
  ]
} as const;

export const DeParticipantAbi = {
  "ABI version": 2,
  "version": "2.2",
  "header": ["pubkey", "time", "expire"],
  "functions": [
    {
      "name": "getDetails",
      "inputs": [
        {"name": "answerId", "type": "uint32"}
      ],
      "outputs": [
        {"name": "root", "type": "address"},
        {"name": "owner", "type": "address"}
      ]
    },
    {
      "name": "getDeAuctionData",
      "inputs": [
        {"name": "answerId", "type": "uint32"},
        {"name": "deAuction", "type": "address"}
      ],
      "outputs": [
        {"name": "exists", "type": "bool"},
        {"name": "stake", "type": "uint128"},
        {"name": "hash", "type": "optional(uint256)"}
      ]
    },
    {
      "name": "createDeAuction",
      "inputs": [
        {"name": "description", "type": "string"},
        {
          "components": [{"name": "min", "type": "uint128"}, {"name": "max", "type": "uint128"}],
          "name": "prices",
          "type": "tuple"
        },
        {"name": "deviation", "type": "uint128"},
        {"name": "fee", "type": "uint128"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "onDeAuctionInit",
      "inputs": [
        {"name": "nonce", "type": "uint64"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "stake",
      "inputs": [
        {"name": "deAuction", "type": "address"},
        {"name": "value", "type": "uint128"},
        {"name": "priceHash", "type": "optional(uint256)"}
      ],
      "outputs": []
    },
    {
      "name": "onStake",
      "inputs": [
        {"name": "nonce", "type": "uint64"},
        {"name": "value", "type": "uint128"},
        {"name": "priceHash", "type": "optional(uint256)"},
        {"name": "success", "type": "bool"}
      ],
      "outputs": []
    },
    {
      "name": "removeStake",
      "inputs": [
        {"name": "deAuction", "type": "address"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "onRemoveStake",
      "inputs": [
        {"name": "nonce", "type": "uint64"},
        {"name": "value", "type": "uint128"},
        {"name": "success", "type": "bool"}
      ],
      "outputs": []
    },
    {
      "name": "confirmPrice",
      "inputs": [
        {"name": "deAuction", "type": "address"},
        {"name": "price", "type": "uint128"},
        {"name": "salt", "type": "uint256"}
      ],
      "outputs": []
    },
    {
      "name": "onConfirmPrice",
      "inputs": [
        {"name": "nonce", "type": "uint64"},
        {"name": "success", "type": "bool"}
      ],
      "outputs": []
    },
    {
      "name": "claim",
      "inputs": [
        {"name": "deAuction", "type": "address"}
      ],
      "outputs": []
    },
    {
      "name": "onClaim",
      "inputs": [
        {"name": "nonce", "type": "uint64"},
        {"name": "success", "type": "bool"}
      ],
      "outputs": []
    },
    {
      "name": "calcPriceHash",
      "inputs": [
        {"name": "price", "type": "uint128"},
        {"name": "salt", "type": "uint256"}
      ],
      "outputs": [
        {"name": "hash", "type": "uint256"}
      ]
    },
    {
      "name": "calcAddress",
      "inputs": [
        {"name": "stateInit", "type": "cell"}
      ],
      "outputs": [
        {"name": "value0", "type": "address"}
      ]
    },
    {
      "name": "constructor",
      "inputs": [],
      "outputs": []
    },
    {
      "name": "_platformCode",
      "inputs": [],
      "outputs": [
        {"name": "_platformCode", "type": "cell"}
      ]
    },
    {
      "name": "_root",
      "inputs": [],
      "outputs": [
        {"name": "_root", "type": "address"}
      ]
    },
    {
      "name": "_owner",
      "inputs": [],
      "outputs": [
        {"name": "_owner", "type": "address"}
      ]
    },
    {
      "name": "_lock",
      "inputs": [],
      "outputs": [
        {"name": "_lock", "type": "bool"}
      ]
    },
    {
      "name": "_stakes",
      "inputs": [],
      "outputs": [
        {"name": "_stakes", "type": "map(address,uint128)"}
      ]
    },
    {
      "name": "_hashes",
      "inputs": [],
      "outputs": [
        {"name": "_hashes", "type": "map(address,optional(uint256))"}
      ]
    }
  ],
  "data": [],
  "events": [
    {
      "name": "NewDeAuction",
      "inputs": [
        {"name": "deAuction", "type": "address"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "Stake",
      "inputs": [
        {"name": "deAuction", "type": "address"},
        {"name": "value", "type": "uint128"},
        {"name": "priceHash", "type": "optional(uint256)"}
      ],
      "outputs": []
    },
    {
      "name": "RemoveStake",
      "inputs": [
        {"name": "deAuction", "type": "address"},
        {"name": "value", "type": "uint128"}
      ],
      "outputs": []
    },
    {
      "name": "ConfirmPrice",
      "inputs": [
        {"name": "deAuction", "type": "address"}
      ],
      "outputs": []
    },
    {
      "name": "Claim",
      "inputs": [
        {"name": "deAuction", "type": "address"}
      ],
      "outputs": []
    }
  ],
  "fields": [
    {"name": "_pubkey", "type": "uint256"},
    {"name": "_timestamp", "type": "uint64"},
    {"name": "_constructorFlag", "type": "bool"},
    {"name": "_platformCode", "type": "cell"},
    {"name": "_root", "type": "address"},
    {"name": "_owner", "type": "address"},
    {"name": "_lock", "type": "bool"},
    {"name": "_stakes", "type": "map(address,uint128)"},
    {"name": "_hashes", "type": "map(address,optional(uint256))"}
  ]
} as const;


