import React, {useEffect, useState} from "react";
import {Address, Contract} from "everscale-inpage-provider";
import {ever} from "../Wallet";
import {AuctionAbi, AuctionRootAbi} from "../../common/abi";
import {Button, Card, Col, Collapse, Collapsible, Descriptions, Row, Spin, Tag, Typography} from "@douyinfe/semi-ui";
import {BASE_SCAN_URL} from "../../constants";
import {convertTons} from "../../common";
import {FullContractState} from "everscale-inpage-provider/dist/models";
import moment from "moment";
import {SingleBid} from "../SinglBid";
import {DeAuction} from "../DeBid";

export enum AuctionPhase {
  'Open',
  'DeAuctions Bid',
  'Confirming',
  'Finished',
  'Done'
}


export enum DeAuctionErrorReason {
  OK,
  NO_ACTIVE_AUCTION,
  WRONG_PRICE_RANGE,
  TOO_LONG_DESCRIPTION
}

export enum DeAuctionPhase {
  INITIALIZING,
  SUB_OPEN,
  SUB_CONFIRM,
  SUB_FINISH,
  WAITING_BID,
  BID_MADE,
  BID_CONFIRMED,
  WIN,
  DISTRIBUTION,
  LOSE,
  SLASHED
}

export type AuctionDetails = {
  // phase: AuctionPhase
  root: Address
  fee: string
  deposit: string
  minLotSize: string
  quotingPrice: string
  deBidTime: string
  confirmTime: string
  finishTime: string
  // bidsCount: number
  // confirmBidsCount: number
  // isFinished: boolean
}

export type AuctionProps = {
  root: Contract<typeof AuctionRootAbi>
  address: Address
  displayFull: boolean
  deAucFrom: number
  deAucTo: number
  contract_?: Contract<typeof AuctionAbi>
  state_?: FullContractState
  // Contract<typeof AuctionAbi>

}

export const Auction: React.FC<AuctionProps> = ({
                                                  root,
                                                  address,
                                                  contract_,
                                                  state_,
                                                  displayFull,
                                                  deAucFrom,
                                                  deAucTo
                                                }) => {
  const {Text} = Typography;

  const [details, setDetails] = useState<AuctionDetails>();
  const [contract, setContract] = useState<Contract<typeof AuctionAbi>>();
  const [status, setStatus] = useState<AuctionPhase>(0);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isDisplayFull, setDisplayFull] = useState<boolean>(displayFull);
  const [displayData, setDisplayData] = useState<any[]>();
  const [contractState, setContractState] = useState<FullContractState>();
  const [nonce, setNonce] = useState<number>();
  const [bidsCount, setBidsCount] = useState<number>(0);
  const [confirmedBids, setConfirmedBids] = useState<number>(0);
  useEffect(() => {
    (async () => {
      // const auctionAddress = (await root.methods.expectedAuction({answerId: 0, nonce}).call()).auction;
      const _contract = contract_ ? contract_ : new ever.Contract(AuctionAbi, address);
      const _contractState = state_ ? state_ : (await ever.getFullContractState({address: address})).state
      setContractState(_contractState);
      setContract(_contract);
      setNonce(Number((await _contract.methods._nonce({}).call({cachedState: _contractState}))['_nonce']));
      const _details = (await _contract.methods.getDetails({answerId: 0}).call({cachedState: _contractState})).details as AuctionDetails;
      const _bidsCount = (await _contract.methods._bidsCount({}).call({cachedState: _contractState}))._bidsCount;
      const _confirmedBidsCount = (await _contract.methods._confirmBidsCount({}).call({cachedState: _contractState}))._confirmBidsCount;
      // const _confirmedBidsCount = (await _contract.methods.getDetails({answerId: 0}).call({cachedState: _contractState})).details as AuctionDetails;
      setBidsCount(Number(_bidsCount));
      setConfirmedBids(Number(_confirmedBidsCount));
      const _finished = (await _contract.methods._finished({}).call({cachedState: _contractState}))._finished;
      setDetails(_details)
      setStatus(Number((await _contract.methods.getPhase({answerId: 0}).call({cachedState: _contractState})).phase) as AuctionPhase)
      setLoading(false);
      let data = [
        {key: 'Quoting Price', value: convertTons(_details.quotingPrice) + ' EVER'},
        {key: 'Minimal lot size', value: convertTons(_details.minLotSize) + ' EVER'},
        {key: 'Finish time', value: moment.unix(Number(_details.finishTime)).format(' hh:mm:ss, MMMM Do YYYY')},
        {key: 'Confirm time', value: moment.unix(Number(_details.confirmTime)).format(' hh:mm:ss, MMMM Do YYYY')},
        {key: 'DeBid time', value: moment.unix(Number(_details.deBidTime)).format(' hh:mm:ss, MMMM Do YYYY')},
        {key: 'Fee', value: convertTons(_details.fee) + ' EVER'},
        {key: 'Deposit', value: convertTons(_details.deposit) + ' EVER'},
        {
          key: 'Address',
          value: <Text link={{href: BASE_SCAN_URL + 'accounts/' + address.toString()}}>
            {address.toString()}
          </Text>
        },
      ];
      if (_finished) {
        const winner = (await _contract.methods._winner({}).call({cachedState: _contractState}))._winner;
        const winnerData = [
          {
            key: 'Address', value: <Text link={{href: BASE_SCAN_URL + 'accounts/' + winner.owner.toString()}}>
              {winner.owner.toString()}
            </Text>
            // 'price'
            // 'amount'
            // 'value'
          },
          {key: 'Price', value: convertTons(winner.price) + ' EVER'},
          {key: 'Amount', value: convertTons(winner.amount) + ' NEVER'},
          {key: 'Value', value: convertTons(winner.value) + ' EVER'},
        ]
        data = [{key: 'Winner', value: <Descriptions data={winnerData}/>}, ...data];
      }
      setDisplayData(data);
    })()


  }, []);
  const deAuctions = [];
  for (let i = 1; i < deAucTo - deAucFrom; i++) {
    deAuctions.push(
      <Collapse.Panel header={`DeAuction #${i - 1}(${deAucFrom + i})`} itemKey={i.toString()}>
        <DeAuction root={root} nonce={deAucFrom + i}></DeAuction>
      </Collapse.Panel>
    )
  }
  return (
    <>
      {isLoading ? (
        <>
          <Card>
            <Spin size="large"/>
          </Card>
        </>
      ) : (
        <Card
          title={'NEVER Auction #' + nonce}
          headerExtraContent={
            <>
              <Tag style={{margin: 0}}>{AuctionPhase[status]}</Tag>
            </>
          }
        >
          <Collapsible isOpen={isDisplayFull}>
            <Descriptions data={displayData}/>
            <Collapse>
              <Collapse.Panel header="Bids" itemKey="1" extra={bidsCount}>
                {/*<Collapse>*/}
                {/*  <Collapse.Panel header="Confirmed bids" itemKey="1">*/}
                {/*  <SingleBid/>*/}
                {/*  </Collapse.Panel>*/}
                {/*</Collapse>*/}
              </Collapse.Panel>
              <Collapse.Panel header="Confirmed bids" itemKey="2" extra={confirmedBids}>
              </Collapse.Panel>
              <Collapse.Panel header="DeAuctions" itemKey="3" extra={deAuctions.length}>
                {/*{deAuctions.length > 0 && (*/}
                {/*  deAuctions*/}
                {/*)}*/}
              </Collapse.Panel>
            </Collapse>
          </Collapsible>
          <Row type="flex" justify="center">
            <Col span={2} style={{paddingTop: '12'}}>
              {isDisplayFull ? (
                <Button onClick={() => setDisplayFull(false)}>Show less</Button>
              ) : (
                <Button onClick={() => setDisplayFull(true)}>Show more</Button>
              )}
            </Col>
          </Row>

        </Card>
      )}
    </>
  )
}

// export const AuctionElement: React.FC<AuctionItemProps> = () => {
//   return (
//     <>
//     </>
//   )
// }