import {Permissions, ProviderRpcClient, Subscriber} from 'everscale-inpage-provider';

import {Mutex} from '@broxus/await-semaphore';
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {convertAddress, convertTons} from "../../common";
import {Button, ButtonGroup, Typography} from "@douyinfe/semi-ui";
import {IconExit, IconLink} from '@douyinfe/semi-icons';
import {EverscaleStandaloneClient} from "everscale-standalone-client";

const walletMutex: Mutex = new Mutex();
let walletSubscriber: Subscriber | undefined = undefined;

export const ever = new ProviderRpcClient({
  fallback: () =>
    EverscaleStandaloneClient.create({
      connection: 'testnet',
    }),
});

const connectToWallet = async () => {
  await ever.requestPermissions({
    permissions: ['basic', 'accountInteraction']
  });
};

const changeAccount = async () => {
  await ever.changeAccount();
};

const disconnectFromWallet = async () => {
  await ever.disconnect();
};

export type WalletProps = {
  onInitialized: () => void
}
export const Wallet: React.FC<WalletProps> = ({onInitialized}) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [hasTonProvider, setHasTonProvider] = useState(false);
  const [networkGroup, setNetworkGroup] = useState<string>('mainnet');
  const [walletAccount, setWalletAccount] = useState<Permissions['accountInteraction']>();
  const [walletBalance, setWalletBalance] = useState<string>();

  const {Text} = Typography;

  useEffect(() => {
    if (walletAccount == null) {
      return;
    }

    walletMutex
      .use(async () => {
        walletSubscriber = new ever.Subscriber();
        const {state} = await ever.getFullContractState({
          address: walletAccount.address
        });

        setWalletBalance(state?.balance || '0');
        walletSubscriber.states(walletAccount.address).on(state => {
          setWalletBalance(state.state.balance);
        });
      })
      .catch(console.error);
    return () => {
      walletMutex
        .use(async () => {
          await walletSubscriber?.unsubscribe();
          setWalletBalance(undefined);
        })
        .catch(console.error);
    };
  }, [walletAccount]);

  useEffect(() => {
    ever.hasProvider().then(async hasTonProvider => {
      setHasTonProvider(hasTonProvider);
      await ever.ensureInitialized();
      if (hasTonProvider) {
        (await ever.subscribe('permissionsChanged')).on('data', event => {
          onInitialized();
          setWalletAccount(event.permissions.accountInteraction);
        });

        (await ever.subscribe('networkChanged')).on('data', event => {
          onInitialized();
          setNetworkGroup(event.selectedConnection);
        });

        const currentProviderState = await ever.getProviderState();
        setNetworkGroup(currentProviderState.selectedConnection);
        if (currentProviderState.permissions.accountInteraction != null) {
          await connectToWallet();
        }
      } else {
        await ever.requestPermissions({
          permissions: ['basic'],
        });
        onInitialized();
      }
    });
  }, []);
  const onConnect = async () => {
    try {
      setIsConnecting(true);
      await connectToWallet();
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <>
      {hasTonProvider ? (
        walletAccount?.address == null ? (
          <Button
            loading={isConnecting}
            onClick={onConnect}
          >
            <strong>Connect wallet</strong>
          </Button>
        ) : (
          <>
            <ButtonGroup type={'primary'}>
              <>
                <CopyToClipboard text={walletAccount?.address.toString()}>
                  <Button>{convertAddress(walletAccount?.address.toString())}</Button>
                </CopyToClipboard>

                <Button onClick={disconnectFromWallet} icon={<IconExit/>}>

                </Button>
              </>
            </ButtonGroup>

          </>
        )
      ) : (
        <Text
          link={{
            href: 'https://chrome.google.com/webstore/detail/ton-crystal-wallet/cgeeodpfagjceefieflmdfphplkenlfk',
            target: "_blank"
          }}
          icon={<IconLink/>}
        >
          Install wallet
        </Text>
        // <a
        //   className="button is-light"
        //   target="_blank"
        //   href="https://chrome.google.com/webstore/detail/ton-crystal-wallet/cgeeodpfagjceefieflmdfphplkenlfk"
        // >
        //   <strong>Install wallet</strong>
        //   <span className="icon">
        // <i className="fa fa-external-link-alt"/>
        // </span>
        // </a>
      )}
    </>
  )
}
